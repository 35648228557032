import React from 'react'
import Card from './Card'

const CardList = ({ characters }) => {
  return (
    <div className="tc ma2">
      {characters.map((person, i) => {
        return (
          <Card
            key={i}
            id={i}
            name={person.name}
            height={person.height}
            mass={person.mass}
            hair_color={person.hair_color}
            skin_color={person.skin_color}
            eye_color={person.eye_color}
            birth_year={person.birth_year}
            gender={person.gender}
          />
        )
      })}
    </div>
  )
}

export default CardList
